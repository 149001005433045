@charset "utf-8";

// IBM Plex Mono
@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono"), url("../fonts/ibm-plex-mono/IBMPlexMono-Regular.woff2") format("woff2"), url("../fonts/ibm-plex-mono/IBMPlexMono-Regular.woff") format("woff"), url("../fonts/ibm-plex-mono/IBMPlexMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono Italic"), url("../fonts/ibm-plex-mono/IBMPlexMono-Italic.woff2") format("woff2"), url("../fonts/ibm-plex-mono/IBMPlexMono-Italic.woff") format("woff"), url("../fonts/ibm-plex-mono/IBMPlexMono-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono SemiBold"), url("../fonts/ibm-plex-mono/IBMPlexMono-SemiBold.woff2") format("woff2"), url("../fonts/ibm-plex-mono/IBMPlexMono-SemiBold.woff") format("woff"), url("../fonts/ibm-plex-mono/IBMPlexMono-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono SemiBold Italic"), url("../fonts/ibm-plex-mono/IBMPlexMono-SemiBoldItalic.woff2") format("woff2"), url("../fonts/ibm-plex-mono/IBMPlexMono-SemiBoldItalic.woff") format("woff"), url("../fonts/ibm-plex-mono/IBMPlexMono-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono Bold"), url("../fonts/ibm-plex-mono/IBMPlexMono-Bold.woff2") format("woff2"), url("../fonts/ibm-plex-mono/IBMPlexMono-Bold.woff") format("woff"), url("../fonts/ibm-plex-mono/IBMPlexMono-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono Bold Italic"), url("../fonts/ibm-plex-mono/IBMPlexMono-BoldItalic.woff2") format("woff2"), url("../fonts/ibm-plex-mono/IBMPlexMono-BoldItalic.woff") format("woff"), url("../fonts/ibm-plex-mono/IBMPlexMono-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono Medium"), url("../fonts/ibm-plex-mono/IBMPlexMono-Medium.woff2") format("woff2"), url("../fonts/ibm-plex-mono/IBMPlexMono-Medium.woff") format("woff"), url("../fonts/ibm-plex-mono/IBMPlexMono-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

// IBM Plex Sans
@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans"), url("../fonts/ibm-plex-sans/IBMPlexSans-Regular.woff2") format("woff2"), url("../fonts/ibm-plex-sans/IBMPlexSans-Regular.woff") format("woff"), url("../fonts/ibm-plex-sans/IBMPlexSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Italic"), url("../fonts/ibm-plex-sans/IBMPlexSans-Italic.woff2") format("woff2"), url("../fonts/ibm-plex-sans/IBMPlexSans-Italic.woff") format("woff"), url("../fonts/ibm-plex-sans/IBMPlexSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans SemiBold"), url("../fonts/ibm-plex-sans/IBMPlexSans-SemiBold.woff2") format("woff2"), url("../fonts/ibm-plex-sans/IBMPlexSans-SemiBold.woff") format("woff"), url("../fonts/ibm-plex-sans/IBMPlexSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans SemiBold Italic"), url("../fonts/ibm-plex-sans/IBMPlexSans-SemiBoldItalic.woff2") format("woff2"), url("../fonts/ibm-plex-sans/IBMPlexSans-SemiBoldItalic.woff") format("woff"), url("../fonts/ibm-plex-sans/IBMPlexSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Bold"), url("../fonts/ibm-plex-sans/IBMPlexSans-Bold.woff2") format("woff2"), url("../fonts/ibm-plex-sans/IBMPlexSans-Bold.woff") format("woff"), url("../fonts/ibm-plex-sans/IBMPlexSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Bold Italic"), url("../fonts/ibm-plex-sans/IBMPlexSans-BoldItalic.woff2") format("woff2"), url("../fonts/ibm-plex-sans/IBMPlexSans-BoldItalic.woff") format("woff"), url("../fonts/ibm-plex-sans/IBMPlexSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Medium"), url("../fonts/ibm-plex-sans/IBMPlexSans-Medium.woff2") format("woff2"), url("../fonts/ibm-plex-sans/IBMPlexSans-Medium.woff") format("woff"), url("../fonts/ibm-plex-sans/IBMPlexSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


// IBM Plex Serif
@font-face {
  font-family: "IBM Plex Serif";
  src: local("IBM Plex Serif"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Regular.woff2") format("woff2"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Regular.woff") format("woff"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Serif";
  src: local("IBM Plex Serif Italic"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Italic.woff2") format("woff2"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Italic.woff") format("woff"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Serif";
  src: local("IBM Plex Serif SemiBold"), url("../fonts/ibm-plex-serif/IBMPlexSerif-SemiBold.woff2") format("woff2"), url("../fonts/ibm-plex-serif/IBMPlexSerif-SemiBold.woff") format("woff"), url("../fonts/ibm-plex-serif/IBMPlexSerif-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Serif";
  src: local("IBM Plex Serif SemiBold Italic"), url("../fonts/ibm-plex-seif/IBMPlexSerif-SemiBoldItalic.woff2") format("woff2"), url("../fonts/ibm-plex-serif/IBMPlexSerif-SemiBoldItalic.woff") format("woff"), url("../fonts/ibm-plex-serif/IBMPlexSerif-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Serif";
  src: local("IBM Plex Serif Bold"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Bold.woff2") format("woff2"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Bold.woff") format("woff"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Serif";
  src: local("IBM Plex Serif Bold Italic"), url("../fonts/ibm-plex-serif/IBMPlexSerif-BoldItalic.woff2") format("woff2"), url("../fonts/ibm-plex-serif/IBMPlexSerif-BoldItalic.woff") format("woff"), url("../fonts/ibm-plex-serif/IBMPlexSerif-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Serif";
  src: local("IBM Plex Serif Medium"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Medium.woff2") format("woff2"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Medium.woff") format("woff"), url("../fonts/ibm-plex-serif/IBMPlexSerif-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
